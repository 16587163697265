<template>
    <footer>
        <div class="foot_wrap">
            <div class="bxinfo eng_font"><strong>SILD</strong><a :href="this.foUrl" target="_blank">{{ foUrl }}</a></div>
            <div class="bxinfo"><strong><span class="eng_font">SILD</span> 파트너스</strong><a :href="this.boUrl" target="_blank" class="eng_font">{{ boUrl }}</a></div>
            <div class="bxinfo">
                <strong>고객센터</strong>
                <ul>
                    <li>대표번호 : <a href="tel:+820216600393">1660-0393</a></li>
                    <li>이메일 : <a :href="'mailto:'+ this.helpMailUrl" class="eng_font">{{ helpMailUrl }}</a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
export default ({
    name: 'FooterComp',
    data() {    
        return {
            foUrl: 'https://www.sild.app',
            boUrl: 'https://partners.sild.app',
            helpMailUrl: 'help@sild.app'
        };
    }
})
</script>

<style scoped>
footer {border-top:1px solid #000;font-size:12px;line-height:15px}
.foot_wrap {overflow:hidden;width:740px;margin:0 auto;padding:20px 0 0 60px;}
.bxinfo {float:left;width:33.3%}
.bxinfo strong {display:block;margin-bottom:10px;font-size:14px}
</style>
