<template>
    <ul class="bx_benefit">
        <li>
            <strong><span class="purp">01</span> 자동 등록</strong>
            <p>
                카페24에 상품을 등록하시면, 자동으로 <span class="eng_font">SILD</span>에 상품이 등록됩니다.
            </p>
        </li>
        <li>
            <strong><span class="purp">02</span> 상품 노출 증가</strong>
            <p>
                연동된 상품은 <span class="eng_font">SILD</span>의 많은 고객과 해외바이어에게 함께 노출됩니다.    
            </p>
        </li>
        <li>
            <strong><span class="purp">03</span> 추가 매출 효과 기대</strong>
            <p>
                상품연동 서비스를 통해 <span class="eng_font">SILD</span>에서 추가매출 효과를 기대할 수 있습니다.
            </p>
        </li>
    </ul>
</template>

<script>
export default ({
    name: 'SynkInfo',
    setup() {

    },
})
</script>
<style scoped>
.bx_benefit {
    overflow: hidden;
    width: 420px;
    margin: 0 auto;
}
.bx_benefit li{
    margin-bottom: 20px;
}
.bx_benefit li p {
    font-weight: 400;   
    font-size: 14px;
    line-height: 17px
}

.bx_benefit li strong {
    display: block;
    margin-bottom: 10px;
    padding: 0 0 2px 10px;
    font-weight: 400;
    font-size: 20px;
    border-left: 2px solid #000000;
    border-bottom: 2px solid  #000000;
}
</style>
