<template>
    <h2>연동이 완료되었습니다.</h2>
    <SyncInfo />
</template>

<script>
import SyncInfo from '@/components/SyncInfo.vue'

export default ({
    name: 'SyncSuccess',
    setup() {
        
    },
    components:{
        SyncInfo
    }
})
</script>

<style scoped>

</style>
