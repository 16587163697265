<template>
  <div class="wrap">
    <h1>
      <svg width="200" height="42" viewBox="0 0 200 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1362_14019)">
          <path d="M7.71038 27.6574C7.71038 30.2263 9.11599 32.1539 11.9272 33.4402C14.7385 34.7265 19.6078 35.3678 26.5389 35.3678C32.1241 35.3678 36.147 34.8645 38.6078 33.8578C41.0648 32.8511 42.2952 31.3448 42.2952 29.3315C42.2952 27.8774 41.859 26.8074 40.9828 26.1176C40.1066 25.4278 38.5071 24.917 36.1769 24.5815C33.8503 24.2459 30.0772 23.9104 24.8611 23.5748C19.1231 23.2393 14.6005 22.6613 11.2859 21.8411C7.97137 21.0208 5.52179 19.783 3.93721 18.1276C2.35263 16.4722 1.56221 14.209 1.56221 11.3381C1.56221 7.27788 3.53454 4.37344 7.48294 2.62108C11.4351 0.876179 17.1172 0 24.5293 0C31.9414 0 37.7876 1.11853 41.9597 3.35185C46.1318 5.58518 48.2346 8.88483 48.2719 13.2396H40.6174C40.6174 11.5283 40.0507 10.1599 38.9135 9.13463C37.7764 8.10932 35.9606 7.35618 33.4663 6.87148C30.972 6.38679 27.6761 6.14444 23.5785 6.14444C19.8538 6.14444 16.9494 6.3495 14.8615 6.75963C12.7736 7.16976 11.3158 7.77376 10.4769 8.57537C9.63797 9.37698 9.22039 10.4098 9.22039 11.6774C9.22039 12.9451 9.64916 13.989 10.5067 14.6937C11.3642 15.4021 12.8892 15.9502 15.0889 16.3417C17.285 16.7332 20.6368 17.0762 25.1445 17.3744C31.4381 17.8219 36.1955 18.314 39.4206 18.8546C42.642 19.3953 45.2034 20.4392 47.1012 21.9828C48.9989 23.5301 49.9497 25.8454 49.9497 28.94C49.9497 33.6714 47.8916 36.9487 43.7754 38.7719C39.6592 40.5951 33.9324 41.5085 26.5948 41.5085C18.1015 41.5085 11.6103 40.4571 7.12501 38.3505C2.63599 36.2514 0.26099 32.6833 0 27.6574H7.71038Z" fill="#231815"/>
          <path d="M55.8406 0.730774H63.2825V41.1208H55.8406V0.730774Z" fill="#231815"/>
          <path d="M70.6312 41.1208V0.730774H78.0247V33.7646H144.611V41.1208H70.6312Z" fill="#231815"/>
          <path d="M181.492 8.3181C187.584 8.3181 192.539 13.165 192.539 19.2424V22.6502C192.539 28.7275 187.584 33.7609 181.492 33.7609H159.405V8.3181H181.492ZM181.492 0.782959H152.004V41.1096H181.492C191.712 41.1096 199.996 32.8437 199.996 22.6502V19.2424C199.996 9.04887 191.712 0.782959 181.492 0.782959Z" fill="#231815"/>
        </g>
      <defs>
        <clipPath id="clip0_1362_14019">
          <rect width="200" height="41.5122" fill="white"/>
        </clipPath>
      </defs>
</svg>

    </h1>

    <SyncSuccess v-if="sync === 'true'" />
    <SyncApply v-if="sync === 'false'" />

    <div class="app_downbanner">
      <span class="badge gg_badge">
        <a href="https://play.google.com/store/apps/details?id=com.sildCompany.sildApp" target="_blank"><img
            alt="다운로드하기 Google Play" src="../src/assets/ko_badge_web_generic-2.png" /></a>
      </span>
      <span class="badge ap_badge">
        <a href="https://apps.apple.com/kr/app/sild/id1558640613" target="_blank">
          <svg width="163" height="50" viewBox="0 0 163 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M151.024 0H11.9826C11.5218 0 11.0664 0 10.6068 0.0025C10.2221 0.005 9.84038 0.0122625 9.45194 0.018375C8.60805 0.0282523 7.76615 0.102095 6.93356 0.239263C6.10213 0.379416 5.29674 0.643645 4.54465 1.02301C3.79347 1.40559 3.10709 1.90271 2.51042 2.49634C1.91062 3.0883 1.41064 3.77252 1.02971 4.5227C0.647724 5.27139 0.382854 6.07367 0.244247 6.90185C0.104323 7.72898 0.0290286 8.56565 0.0190396 9.40435C0.00737706 9.7876 0.00614546 10.1721 0 10.5554V39.4482C0.00614546 39.8364 0.00737706 40.2124 0.0190396 40.6006C0.0290317 41.4393 0.104326 42.2759 0.244247 43.103C0.382471 43.9317 0.647357 44.7344 1.02971 45.4834C1.41047 46.2311 1.91052 46.9126 2.51042 47.5013C3.10483 48.0975 3.79165 48.595 4.54465 48.9746C5.29674 49.355 6.10204 49.6208 6.93356 49.7631C7.7663 49.8992 8.60811 49.9731 9.45194 49.9841C9.84038 49.9926 10.2221 49.9975 10.6068 49.9975C11.0664 50 11.5218 50 11.9826 50H151.024C151.476 50 151.935 50 152.386 49.9975C152.769 49.9975 153.162 49.9926 153.545 49.9841C154.387 49.9737 155.227 49.8998 156.059 49.7631C156.893 49.6198 157.701 49.3541 158.457 48.9746C159.209 48.5948 159.895 48.0973 160.489 47.5013C161.087 46.9103 161.589 46.2293 161.974 45.4834C162.353 44.7339 162.616 43.9312 162.752 43.103C162.892 42.2758 162.97 41.4394 162.985 40.6006C162.99 40.2124 162.99 39.8364 162.99 39.4482C163 38.9941 163 38.5425 163 38.081V11.9202C163 11.4624 163 11.0083 162.99 10.5554C162.99 10.1721 162.99 9.7876 162.985 9.4043C162.97 8.56553 162.892 7.72904 162.752 6.9018C162.615 6.0741 162.353 5.27188 161.974 4.52265C161.198 3.019 159.968 1.79503 158.457 1.0229C157.701 0.644459 156.893 0.380301 156.059 0.23915C155.228 0.101378 154.387 0.0275097 153.545 0.0182C153.162 0.0121 152.769 0.004775 152.386 0.0023375C151.935 0 151.476 0 151.024 0Z"
              fill="#A6A6A6" />
            <path
              d="M10.613 48.9062C10.23 48.9062 9.85639 48.9014 9.4765 48.8929C8.6895 48.8827 7.90435 48.8145 7.12747 48.689C6.40308 48.5649 5.70133 48.3341 5.04538 48.0041C4.39545 47.6769 3.80266 47.2478 3.28972 46.7334C2.76935 46.225 2.33612 45.6353 2.0072 44.9878C1.67466 44.336 1.44451 43.6374 1.32479 42.9163C1.1955 42.1414 1.12555 41.3579 1.11555 40.5725C1.10758 40.3089 1.09715 39.4311 1.09715 39.4311V10.5554C1.09715 10.5554 1.10826 9.69116 1.11561 9.43725C1.12519 8.6531 1.19474 7.87082 1.32364 7.09716C1.44358 6.37406 1.6739 5.67344 2.00662 5.01954C2.33434 4.37243 2.76516 3.78233 3.28239 3.2721C3.79904 2.75703 4.39373 2.32576 5.04477 1.99401C5.69921 1.66512 6.39963 1.43592 7.12256 1.31409C7.90199 1.1873 8.68987 1.11875 9.47959 1.10901L10.6136 1.09375H152.38L153.528 1.10963C154.31 1.11887 155.091 1.18681 155.863 1.31286C156.594 1.43622 157.301 1.66702 157.963 1.99768C159.268 2.66623 160.329 3.72395 160.998 5.02259C161.326 5.67197 161.552 6.36688 161.671 7.08374C161.801 7.86374 161.874 8.65217 161.889 9.44275C161.893 9.79675 161.893 10.177 161.893 10.5554C161.903 11.0242 161.903 11.4703 161.903 11.9202V38.081C161.903 38.5351 161.903 38.9783 161.893 39.425C161.893 39.8315 161.893 40.2039 161.888 40.5871C161.873 41.3636 161.802 42.138 161.673 42.904C161.556 43.6303 161.327 44.3344 160.995 44.9915C160.663 45.632 160.233 46.2166 159.718 46.7236C159.205 47.2409 158.611 47.6724 157.96 48.0018C157.3 48.3342 156.593 48.5659 155.863 48.689C155.087 48.8152 154.301 48.8834 153.514 48.8929C153.146 48.9014 152.761 48.9062 152.386 48.9062L151.024 48.9087L10.613 48.9062Z"
              fill="black" />
            <path
              d="M47.6641 31.6052H44.5051V35.9668C45.8108 35.991 47.116 35.8953 48.4041 35.6812L48.5201 36.6212C47.0368 36.8512 45.5369 36.9594 44.0356 36.9446H43.3508V30.6543H47.6641L47.6641 31.6052ZM50.2874 33.3569H51.6515V34.314H50.2874V39.1199H49.1221V29.7754H50.2874V33.3569Z"
              fill="white" />
            <path
              d="M61.0206 34.3799V35.3027H57.4308V37.0654H56.2545V35.3027H52.5266V34.3799H61.0206ZM53.5152 31.9129C53.5152 30.8301 54.7799 30.0452 56.7791 30.0452C58.7784 30.0452 60.0542 30.8301 60.0542 31.9129C60.0542 33.0115 58.7784 33.7964 56.7902 33.7964C54.7799 33.7964 53.5152 33.0115 53.5152 31.9128V31.9129ZM54.7357 37.9492H60.0431V38.8672H53.5814V36.2085H54.7357V37.9492ZM54.7025 31.9238C54.7025 32.522 55.5807 32.9077 56.7736 32.9077C58.0052 32.9077 58.8557 32.522 58.8557 31.9238C58.8557 31.3135 58.0107 30.9241 56.7791 30.9241C55.5807 30.9241 54.7025 31.3135 54.7025 31.9238V31.9238Z"
              fill="white" />
            <path
              d="M70.5713 37.0166V37.9395H62.0773V37.0166H65.7389V35.4944H63.1653V32.4292H68.3181V31.2915H63.1487V30.3638H69.4944V33.363H64.3251V34.5715H69.7319V35.4944H66.8987V37.0166L70.5713 37.0166Z"
              fill="white" />
            <path
              d="M80.1227 37.8784H71.6286V36.9556H80.1227L80.1227 37.8784ZM79.0292 31.5015H73.8764V34.0723H79.101V35.0049H72.7277V30.5664H79.0292L79.0292 31.5015Z"
              fill="white" />
            <path
              d="M89.4488 32.9456H83.9702V32.0288H89.4488L89.4488 32.9456ZM86.7586 37.3463C86.3747 37.2985 86.0129 37.1415 85.7165 36.8942C85.4201 36.647 85.2017 36.3198 85.0876 35.9522C84.9736 35.5845 84.9686 35.1919 85.0733 34.8214C85.178 34.451 85.388 34.1185 85.678 33.8639C85.9681 33.6092 86.3258 33.4433 86.7084 33.3859C87.0909 33.3285 87.482 33.3821 87.8346 33.5403C88.1873 33.6986 88.4865 33.9547 88.6964 34.2779C88.9064 34.6011 89.018 34.9777 89.018 35.3625C89.0113 35.6439 88.9462 35.9209 88.8268 36.176C88.7074 36.4312 88.5362 36.6591 88.3241 36.8454C88.1119 37.0316 87.8633 37.1723 87.5939 37.2585C87.3246 37.3447 87.0402 37.3746 86.7586 37.3463ZM88.1786 31.3196H85.3288V30.3809H88.1786L88.1786 31.3196ZM86.7586 36.4332C86.906 36.4423 87.0537 36.4213 87.1927 36.3715C87.3316 36.3217 87.4588 36.2441 87.5664 36.1435C87.6741 36.0429 87.7598 35.9214 87.8185 35.7866C87.8771 35.6518 87.9073 35.5065 87.9073 35.3596C87.9073 35.2127 87.8771 35.0673 87.8185 34.9325C87.7598 34.7977 87.6741 34.6762 87.5664 34.5757C87.4588 34.4751 87.3316 34.3975 87.1927 34.3476C87.0537 34.2978 86.906 34.2768 86.7586 34.286C86.6135 34.2805 86.4687 34.3043 86.333 34.3558C86.1973 34.4073 86.0734 34.4855 85.9688 34.5858C85.8641 34.686 85.7809 34.8062 85.7241 34.9391C85.6672 35.0721 85.6379 35.2151 85.6379 35.3596C85.6379 35.504 85.6672 35.647 85.7241 35.78C85.7809 35.913 85.8641 36.0331 85.9688 36.1334C86.0734 36.2336 86.1973 36.3118 86.333 36.3633C86.4687 36.4148 86.6135 36.4386 86.7586 36.4332ZM91.3536 33.6378H92.7011V34.5875H91.3536V39.12H90.195V29.7815H91.3536L91.3536 33.6378Z"
              fill="white" />
            <path
              d="M94.25 37.4219L93.5934 36.5161C94.5714 36.0989 95.4248 35.4378 96.071 34.5968C96.7172 33.7558 97.1345 32.7633 97.2826 31.7151H94.1174V30.7593H98.5197C98.5066 32.1561 98.0994 33.5211 97.3446 34.699C96.5897 35.8769 95.5175 36.8204 94.25 37.4219ZM101.341 39.126H100.177V29.7754H101.341V39.126Z"
              fill="white" />
            <path
              d="M51.7276 20.6928H46.6442L45.4243 24.278H43.2704L48.0862 11.0114H50.3224L55.1382 24.278H52.9475L51.7276 20.6928ZM47.1707 19.0375H51.2011L49.2129 13.2184H49.1577L47.1707 19.0375Z"
              fill="white" />
            <path
              d="M65.5567 19.4427C65.5567 22.4481 63.9391 24.3793 61.4992 24.3793C60.881 24.4117 60.266 24.2702 59.7248 23.9709C59.1837 23.6717 58.7383 23.2268 58.4396 22.6874H58.3942V27.4774H56.3974V14.6063H58.3292V16.2152H58.366C58.6784 15.6782 59.1313 15.2356 59.6766 14.9344C60.2219 14.6333 60.8391 14.4848 61.4624 14.505C63.9305 14.505 65.5567 16.4447 65.5567 19.4427ZM63.5046 19.4427C63.5046 17.4835 62.4885 16.1969 60.9359 16.1969C59.4104 16.1969 58.3844 17.5116 58.3844 19.4427C58.3844 21.391 59.4104 22.6972 60.9359 22.6972C62.4885 22.6971 63.5046 21.4191 63.5046 19.4427Z"
              fill="white" />
            <path
              d="M76.2782 19.4427C76.2782 22.4481 74.6606 24.3793 72.2208 24.3793C71.6025 24.4117 70.9875 24.2702 70.4464 23.9709C69.9052 23.6717 69.4598 23.2268 69.1612 22.6874H69.1158V27.4774H67.119V14.6063H69.0507V16.2152H69.0876C69.3999 15.6782 69.8528 15.2356 70.3981 14.9344C70.9434 14.6333 71.5606 14.4848 72.184 14.505C74.6521 14.505 76.2782 16.4447 76.2782 19.4427ZM74.2262 19.4427C74.2262 17.4835 73.21 16.1969 71.6575 16.1969C70.132 16.1969 69.106 17.5116 69.106 19.4427C69.106 21.391 70.132 22.6972 71.6575 22.6972C73.21 22.6971 74.2262 21.4191 74.2262 19.4427Z"
              fill="white" />
            <path
              d="M83.3897 20.5817C83.5382 21.8964 84.8219 22.7606 86.5782 22.7606C88.2608 22.7606 89.4721 21.8964 89.4721 20.7111C89.4721 19.6808 88.7419 19.0655 87.0127 18.642L85.2847 18.2282C82.835 17.6398 81.6985 16.5009 81.6985 14.6527C81.6985 12.3639 83.7039 10.7916 86.5512 10.7916C89.3703 10.7916 91.302 12.3639 91.367 14.6527H89.3519C89.2316 13.3283 88.1319 12.5287 86.5229 12.5287C84.9152 12.5287 83.8156 13.338 83.8156 14.5148C83.8156 15.4523 84.5176 16.004 86.237 16.4264L87.706 16.7853C90.4417 17.4286 91.5793 18.5224 91.5793 20.4633C91.5793 22.945 89.5924 24.4989 86.4309 24.4989C83.4731 24.4989 81.4769 22.9816 81.3481 20.5817L83.3897 20.5817Z"
              fill="white" />
            <path
              d="M95.8884 12.3175V14.6063H97.7366V16.1786H95.8883V21.5106C95.8883 22.3383 96.2578 22.724 97.0714 22.724C97.2906 22.7207 97.5093 22.7056 97.7268 22.6788V24.2413C97.3614 24.309 96.9901 24.3396 96.6186 24.3329C94.6488 24.3329 93.8817 23.598 93.8817 21.7218V16.1786H92.4679V14.6063H93.8817V12.3175H95.8884Z"
              fill="white" />
            <path
              d="M98.8302 19.4428C98.8302 16.3995 100.632 14.4867 103.442 14.4867C106.261 14.4867 108.054 16.3995 108.054 19.4428C108.054 22.4945 106.271 24.3976 103.442 24.3976C100.613 24.3976 98.8302 22.4945 98.8302 19.4428ZM106.021 19.4428C106.021 17.3554 105.06 16.1237 103.442 16.1237C101.825 16.1237 100.864 17.3639 100.864 19.4428C100.864 21.5387 101.825 22.7606 103.442 22.7606C105.06 22.7606 106.021 21.5388 106.021 19.4428Z"
              fill="white" />
            <path
              d="M109.701 14.6063H111.605V16.2518H111.652C111.78 15.7378 112.083 15.2836 112.509 14.9654C112.935 14.6473 113.457 14.4847 113.99 14.505C114.22 14.5036 114.45 14.5286 114.674 14.5795V16.4362C114.384 16.3481 114.081 16.3077 113.777 16.3165C113.487 16.3048 113.198 16.3555 112.93 16.4654C112.662 16.5752 112.421 16.7415 112.223 16.9528C112.026 17.1641 111.877 17.4154 111.786 17.6895C111.695 17.9636 111.665 18.254 111.698 18.5407V24.278H109.701V14.6063Z"
              fill="white" />
            <path
              d="M123.877 21.4374C123.657 22.3515 123.112 23.1553 122.342 23.6997C121.572 24.2441 120.629 24.4921 119.689 24.3976C116.861 24.3976 115.105 22.5128 115.105 19.4879C115.105 16.4545 116.87 14.4867 119.607 14.4867C122.296 14.4867 123.987 16.325 123.987 19.2584V19.9383H117.12V20.058C117.089 20.4139 117.133 20.7724 117.252 21.1098C117.37 21.4472 117.559 21.7557 117.806 22.0148C118.054 22.2739 118.354 22.4777 118.686 22.6126C119.019 22.7475 119.377 22.8104 119.736 22.7972C120.207 22.8413 120.681 22.7327 121.085 22.4877C121.49 22.2427 121.804 21.8743 121.982 21.4374H123.877ZM117.129 18.5504H121.991C122.009 18.2304 121.96 17.9102 121.848 17.6099C121.736 17.3096 121.562 17.0356 121.338 16.8052C121.114 16.5747 120.845 16.3927 120.547 16.2706C120.249 16.1485 119.929 16.0889 119.607 16.0956C119.282 16.0936 118.959 16.1557 118.659 16.2782C118.358 16.4007 118.084 16.5813 117.854 16.8095C117.623 17.0378 117.441 17.3091 117.316 17.6079C117.192 17.9067 117.128 18.227 117.129 18.5504Z"
              fill="white" />
            <path
              d="M132.067 11.2347H133.624V23.6456H132.067V17.381H130.927C130.785 19.5941 129.882 21.518 128.057 21.518C126.053 21.518 125.164 19.2035 125.164 16.7328C125.164 14.2609 126.053 11.994 128.057 11.994C129.842 11.994 130.746 13.7921 130.919 15.9345H132.067L132.067 11.2347ZM129.418 16.7328C129.418 14.9103 128.977 13.5187 128.057 13.5187C127.161 13.5187 126.752 14.9103 126.752 16.7328C126.752 18.5626 127.161 19.9384 128.057 19.9384C128.977 19.9384 129.418 18.5626 129.418 16.7328H129.418ZM136.343 10.9686V24.2706H134.787V10.9686H136.343Z"
              fill="white" />
            <path
              d="M143.378 14.0888C143.323 15.2609 143.605 16.4244 144.189 17.4435C144.774 18.4626 145.638 19.2953 146.681 19.8444L145.714 21.0883C144.285 20.28 143.169 19.0192 142.545 17.5067C141.978 19.1529 140.841 20.5447 139.337 21.4325L138.314 20.2277C139.38 19.6163 140.256 18.7255 140.847 17.6526C141.438 16.5796 141.721 15.3658 141.665 14.1437V12.0868H143.378L143.378 14.0888ZM149.645 24.2706H147.978V16.9586H145.296V15.5597H147.978V10.9686H149.645V24.2706Z"
              fill="white" />
            <path
              d="M31.128 25.3759C31.1415 24.3325 31.4202 23.3096 31.938 22.4021C32.4558 21.4947 33.196 20.7322 34.0896 20.1858C33.5219 19.3793 32.7729 18.7157 31.9022 18.2475C31.0315 17.7793 30.0631 17.5195 29.0738 17.4887C26.9634 17.2684 24.9175 18.7447 23.8421 18.7447C22.7459 18.7447 21.0901 17.5106 19.3071 17.547C18.1539 17.5841 17.0299 17.9177 16.0448 18.5152C15.0596 19.1128 14.2469 19.954 13.6858 20.9569C11.2553 25.1424 13.0683 31.2937 15.3965 34.677C16.5614 36.3337 17.9228 38.1842 19.7042 38.1186C21.4473 38.0467 22.0984 37.013 24.2025 37.013C26.2872 37.013 26.898 38.1186 28.7155 38.0769C30.586 38.0467 31.7645 36.4128 32.8885 34.7405C33.7255 33.56 34.3695 32.2553 34.7968 30.8748C33.71 30.4177 32.7827 29.6524 32.1303 28.6746C31.4779 27.6967 31.1293 26.5494 31.128 25.3759Z"
              fill="white" />
            <path
              d="M27.6951 15.2636C28.715 14.0459 29.2174 12.4806 29.0957 10.9003C27.5376 11.0631 26.0983 11.8038 25.0646 12.9749C24.5593 13.5469 24.1722 14.2125 23.9256 14.9334C23.6789 15.6544 23.5776 16.4167 23.6273 17.1766C24.4067 17.1846 25.1777 17.0166 25.8823 16.6852C26.5869 16.3538 27.2067 15.8678 27.6951 15.2636Z"
              fill="white" />
          </svg>
        </a>
      </span>
    </div>
  </div>
  <FooterComp />
</template>

<script>
import SyncSuccess from '@/components/SyncSuccess.vue'
import SyncApply from '@/components/SyncApply.vue'
import FooterComp from '@/components/FooterComp.vue'

export default {
  name: 'App',
  components: {
    SyncSuccess,
    SyncApply,
    FooterComp
  },
  data() {
    return {
      //sync값으로 화면 전환.
      sync: String
    }
  },
  methods: {
    stilready() {
      alert('준비중입니다.');
    }
  },
  created() {
    this.$data.sync = new URLSearchParams(window.location.search).get('sync');
  }
}
</script>

<style>
#app {
  font-family: 'Noto Sans KR', sans-serif;
}

.eng_font {
  font-family: 'Nunito Sans', sans-serif;
}

/* layout */
.wrap {
  margin: 0 auto;
  padding: 152px 0 230px 0;
  width: 800px
}

/* default style */
h1,
h2 {
  text-align: center
}

h1 {
  margin: 0 0 30px 0
}

h2 {
  margin: 0 0 80px 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
}

p {
  margin: 0;
  line-height: 25px
}

ol {
  padding:0;
}
li {
  line-height: 25px
}

.purp {
  color : #7F00FF;
}

/* app_banner */
.app_downbanner {
  margin: 100px 0 0 0;
  padding-left: 193px;
  width: 100%
}

.app_downbanner .badge {
  float: left
}

.app_downbanner .gg_badge {
  margin-right: 30px;
  text-align: right
}

.app_downbanner .gg_badge img {
  width: 186px;
  margin-right: -12px
}

.app_downbanner .ap_badge {
  padding-top: 11px
}
</style>
