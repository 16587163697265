<template>
    <h2>연동 신청이 완료되었습니다.</h2>
    <div class="bx_text">
        <p class="alert">이 <span class="eng_font">App</span>을 동작하기 위해 아래 사항이 선행되어야 합니다.</p>
        <ol>
            <li><span class="purp">01</span> <span class="eng_font">    SILD PARTNERS(<a href="https://partners.sild.app" target="_blank" class="purp">https://partners.sild.app</a>)</span>에서 브랜드 입점 신청을 해주세요.</li>
            <li><span class="purp">02</span> 입점 신청이 승인되면, 상품 연동 및 노출이 진행됩니다.</li>
        </ol>
    </div>    
</template>

<script>
export default ({
    name: 'SyncApply',
    setup() {
        
    }   
})
</script>

<style scoped>
.bx_text p {
    margin-bottom:80px;
    text-align:center;
}

.bx_text ol {
    width:704px;
    margin:0 auto;
}

.bx_text ol li {
    margin-bottom:10px;
    font-weight: 400;
    font-size: 20px
}

p.alert {
    font-weight:500;
    font-size:26px;
    line-height:32px;
}
</style>
